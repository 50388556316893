<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" @click.stop="row.toggleDetails"
			class="mr-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>

		<b-button v-show="selAsset && selAsset.status === 'Stored' && !isViewer" size="sm" v-b-modal.update-asset-details
			v-b-tooltip.hover.top="'Update Asset Details'" variant="warning" @click.stop="updateSelAsset(selAsset)"
			class="mr-1">
			<i class="fa fa-pencil"></i>
		</b-button>
	</div>
</template>

<script>
// Database
import assetDAO from '@/database/assets';

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'asset-row-actions',
	props: {
		row: {
			type: Object,
			required: true
		},
		isViewer: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			selAsset: {},
		};
	},
	async mounted() {
		await this.retrieveSelAsset(this.row.item.assetCode);
	},
	watch: {
		row: async function () {
			if (this.selAsset.assetCode !== this.row.item.assetCode) {
				await this.retrieveSelAsset(this.row.item.assetCode);
			}
		}
	},
	methods: {
		async retrieveSelAsset(assetCode) {
			this.selAsset = await assetDAO.getAssetById(assetCode);
		},
		updateSelAsset(item) {
			this.$store.commit('SET_CURR_ASSET', item);
			EventBus.$emit('onUpdateSelAsset', item);
		},
	}
};
</script>