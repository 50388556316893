<template>
	<b-modal id="mark-as-lost-asset" :title="dialogTitle" ref="modal" ok-title="Mark As Lost" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-2">
					<b-col sm="12">
						<b> <i class="icon-eyeglass"></i> &nbsp;Last Known Location </b>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col sm="4" class="text-sm-right">Company:</b-col>
					<b-col>{{ company }}</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col sm="4" class="text-sm-right">Storage Location:</b-col>
					<b-col>{{ storageLocation }}</b-col>
				</b-row>
				<hr />
				<b-row class="mb-2">
					<b-col sm="12">
						Are you sure to mark asset
						<b-badge pill variant="primary">{{ selAsset.assetCode }}</b-badge>as lost?
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'mark-as-lost-asset',
	components: {
		Loading,
	},
	data() {
		return {
			selAsset: {},
			currUserId: this.$store.getters.loggedUser.id,
			// Check for loader
			isLoading: false,
		};
	},
	mounted() {
		EventBus.$on('onUpdateSelAsset', (selAsset) => {
			this.selAsset = selAsset;
		});
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		dialogTitle: function () {
			return 'Mark as Lost Asset: ' + this.selAsset.assetCode;
		},

		company: function () {
			if (this.selAsset.currentLocation) {
				return this.selAsset.currentLocation.company;
			}
			return '';
		},
		storageLocation: function () {
			if (this.selAsset.currentLocation) {
				return this.selAsset.currentLocation.storageLocation;
			}
			return '';
		},
	},
	methods: {
		handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			this.handleSubmit();
		},

		getParam() {
			let param = {
				currUserId: this.currUserId,
				dateDeactivated: DateUtil.getCurrentTimestamp(),
				assetCodes: [],
			};
			param.assetCodes.push(this.selAsset.assetCode);

			return param;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let param = this.getParam();
				let { data } = await assetApi.markAsLostAssets(param);

				if (data.isSuccess) {
					let assetCode = this.selAsset.assetCode;
					this.$toaster.success(assetCode + ' was marked as lost.');
					EventBus.$emit('onCloseSaveAssets', data.assets);
					this.$refs.modal.hide();
				} else {
					this.$toaster.warning(data.message);
				}

			} catch (_error) {
				this.$toaster.error('Error marking asset(s) as lost. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAsset');
	},
};
</script>