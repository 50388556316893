<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">CHANGE LOGS</span>
                <div class="mb-2 details-view-subtitle">Recent update logs created by {{ isSuperAdmin ? 'all users' :
                    loggedUser.id }}</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>

            <template v-slot:cell(dateUpdated)="row">
                <div>
                    {{ getFormattedDateWithTime(row.item.new.dateUpdated) }}
                </div>
            </template>

            <template v-slot:cell(changes)="row">
                <div class="changed-section" v-if="hasValueChanged(row.item, 'name')">
                    <b>Name</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'name') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'name') }} </b>
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'description')">
                    <b>Description</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'description') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'description') }} </b>
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'serialNo')">
                    <b>Serial No</b>
                    <br>
                    From <b class="old-value numFont"> {{ getOldValue(row.item, 'serialNo') }} </b>
                    to <b class="new-value numFont"> {{ getNewValue(row.item, 'serialNo') }} </b>
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'condition')">
                    <b>Condition</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'condition') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'condition') }} </b>
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'unitCost')">
                    <b>Unit Cost</b>
                    <br>
                    From <b class="old-value numFont"> {{ getOldValue(row.item, 'unitCost') }} </b>
                    to <b class="new-value numFont"> {{ getNewValue(row.item, 'unitCost') }} </b>
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'purchaseDate')">
                    <b>Purchase Date</b>
                    <br>
                    From <b class="old-value"> {{ getFormattedDateWithTime(getOldValue(row.item, 'purchaseDate')) }} </b>
                    to <b class="new-value"> {{ getFormattedDateWithTime(getNewValue(row.item, 'purchaseDate')) }} </b>
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'expirationDate')">
                    <b>Expiration Date</b>
                    <br>
                    From <b class="old-value"> {{ getFormattedDateWithTime(getOldValue(row.item, 'expirationDate')) }} </b>
                    to <b class="new-value"> {{ getFormattedDateWithTime(getNewValue(row.item, 'expirationDate')) }} </b>
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'imgUrl')">
                    <b>Asset Photo</b>
                    <br>
                    From <br />
                    <span v-if="getOldValue(row.item, 'imgUrl') === '-'">
                        <b class="old-value"> - </b>
                    </span>
                    <span v-else>
                        <b-img v-img-orientation-changer :src="getAssetPhotoUrl(getOldValue(row.item, 'imgUrl'))"
                            alt="Responsive image" thumbnail fluid class="asset-photo" />
                    </span>

                    <br />
                    <br />
                    to <br />
                    <span v-if="getNewValue(row.item, 'imgUrl') === '-'">
                        <b class="new-value"> {{ getNewValue(row.item, 'imgUrl') }} </b>
                    </span>
                    <span v-else>
                        <b-img v-img-orientation-changer :src="getAssetPhotoUrl(getNewValue(row.item, 'imgUrl'))"
                            alt="Responsive image" thumbnail class="asset-photo" />
                    </span>
                </div>

            </template>

            <template v-slot:cell(updatedBy)="row">{{ row.item.new.updatedBy }}</template>
        </b-table>
        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-4 mb-2">
            <b-col sm="12" class="text-sm-right">
                <b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
                    <i class="icon-arrow-up"></i>
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// API & Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import _ from 'lodash';

export default {
    name: 'asset-change-logs-details-view',
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            changeLogs: [],
            fields: [
                'dateUpdated',
                'changes',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            assetCode: '',

            loggedUser: this.$store.getters.loggedUser,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
        };
    },
    watch: {
        assetCode: async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.retrieveChangeLog(newVal);
            }
        }
    },
    async mounted() {
        this.assetCode = this.row.item.assetCode;
        await this.retrieveChangeLog(this.assetCode);
    },
    methods: {
        async retrieveChangeLog(assetCode) {
            if (assetCode) {
                let param = {
                    collection: 'assets',
                    id: assetCode,
                    userId: !this.isSuperAdmin ? this.loggedUser.id : ''
                }

                let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
                this.changeLogs = Object.values(results);

                this.filterChangeLogs(this.changeLogs);
                this.totalRows = _.size(this.changeLogs);
            }
        },

        filterChangeLogs(changeLogs) {
            const filteredLogs = _.filter(changeLogs, (log) => {
                const oldLog = log.old ? log.old : {};
				const newLog = log.new ? log.new : {};

                let oldDetails = oldLog.details ? oldLog.details : {};
                let newDetails = newLog.details ? newLog.details : {};
                // Name
                const oldName = oldDetails.name ? oldDetails.name : '';
                const newName = newDetails.name ? newDetails.name : '';

                // Description
                const oldDesc = oldDetails.description ? oldDetails.description : '';
                const newDesc = newDetails.description ? newDetails.description : '';

                // Serial No
                const oldSerialNo = oldDetails.serialNo ? oldDetails.serialNo : '';
                const newSerialNo = newDetails.serialNo ? newDetails.serialNo : '';

                // Condition
                const oldCondition = oldDetails.condition ? oldDetails.condition : '';
                const newCondition = newDetails.condition ? newDetails.condition : '';

                // Unit Cost
                const oldUnitCost = oldDetails.unitCost ? oldDetails.unitCost : 0;
                const newUnitCost = newDetails.unitCost ? newDetails.unitCost : 0;

                // Purchase Date
                const oldPurchaseDate = oldDetails.purchaseDate ? oldDetails.purchaseDate : 0;
                const newPurchaseDate = newDetails.purchaseDate ? newDetails.purchaseDate : 0;

                // Expiration Date
                const oldExpirationDate = oldDetails.expirationDate ? oldDetails.expirationDate : 0;
                const newExpirationDate = newDetails.expirationDate ? newDetails.expirationDate : 0;

                // Photo
                const oldImgUrl = oldDetails ? oldDetails.imgUrl : '';
                const newImgUrl = newDetails ? newDetails.imgUrl : '';

                return oldName !== newName ||
                    oldDesc !== newDesc ||
                    oldSerialNo !== newSerialNo ||
                    oldCondition !== newCondition ||
                    oldUnitCost !== newUnitCost ||
                    oldPurchaseDate !== newPurchaseDate ||
                    oldExpirationDate !== newExpirationDate ||
                    oldImgUrl !== newImgUrl;
            });
            this.changeLogs = filteredLogs;
        },

        hasValueChanged(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldDetails = oldLog.details ? oldLog.details : {};
            let newDetails = newLog.details ? newLog.details : {};

            let oldValue = oldDetails[fieldName] ? oldDetails[fieldName] : "-";
            let newValue = newDetails[fieldName] ? newDetails[fieldName] : "-";
            return oldValue !== newValue;
        },

        // UTILS
        getFormattedDateWithTime(date) {
            if (date !== "-") {
                return DateUtil.getFormattedDateWithTime(date);
            }
            return "-";
        },
        getOldValue(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let oldDetails = oldLog.details ? oldLog.details : {};
            let value = oldDetails[fieldName] ? oldDetails[fieldName] : "-";
            return value;
        },
        getNewValue(log, fieldName) {
            let newLog = log.new ? log.new : {};
            let newDetails = newLog.details ? newLog.details : {};
            let value = newDetails[fieldName] ? newDetails[fieldName] : "-";
            return value;
        },
        getAssetPhotoUrl(imgUrl) {
            if (!ValidationUtil.isValidURL(imgUrl)) {
                imgUrl = 'img/asset_placeholder.png';
            }
            return imgUrl;
        }
    }
}
</script>

<style scoped>
.new-value {
    color: green;
}

.old-value {
    color: red;
}

.changed-section {
    margin-bottom: 10px;
}

.asset-photo {
    width: 20%;
}
</style>