<template>
	<b-modal id="activate-assets" title="Activate Assets" size="lg" ref="modal" ok-title="Activate" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-1">
					<b-col sm="12">
						NOTE: You can only activate assets with
						<b-badge variant="secondary">Inactive</b-badge> and
						<b-badge variant="danger">Lost</b-badge> status. You have selected
						<b>{{ assetsToActivate.length }}</b> inactive and/or lost asset(s) as
						follows. Please categorize the asset condition as well (Good, Damaged, or Others).
					</b-col>
				</b-row>
				<b-row class="mb-0">
					<b-col sm="12" class="my-2">
						<b-table show-empty striped hover fixed :items="assetsToActivate" :fields="fields"
							:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
							<template v-slot:cell(condition)="row">
								<b-form-select :name="row.item.assetCode + ' Condition'"
									v-model="selConditions[row.item.assetCode]" v-validate="'required'"
									:options="getAssetConditions(row.item)" class="mr-2" />
								<span v-show="errors.has(row.item.assetCode + ' Condition')" class="help-block">{{
									errors.first(row.item.assetCode + ' Condition')
								}}</span>
							</template>
						</b-table>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col md="4" class="my-1">
						<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
					</b-col>
					<b-col md="2" sm="12" offset-md="2" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="6" class="my-1">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-row class="mb-2">
					<b-col sm="12">To activate these assets, please determine their initial storage
						location.</b-col>
				</b-row>
				<b-row class="my-12">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Company">
							<v-select name="Company" class="style-chooser" label="text" :options="companyOptions"
								:reduce="(company) => company.value" v-model="selCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">{{
								errors.first('Company')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Storage Location">
							<v-select name="Storage Location" class="style-chooser" label="text"
								:options="storageLocationOptions" :reduce="(loc) => loc.value" v-model="selStorageLocation"
								v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a storage location
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Storage Location')" class="help-block">{{
								errors.first('Storage Location')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="my-12">
					<b-col sm="6">
						<b-form-group label="Activation Date">
							<date-range-picker opens="center" :single-date-picker="true" :timePicker="true"
								:timePicker24Hour="false" :maxDate="maxDate" v-model="selActivationDate" applyLabel="Apply"
								cancelLabel="Cancel" :style="{ width: '100%' }">
								<div slot="input">{{ activationDate }}</div>
							</date-range-picker>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { LocationUtil } from '@/utils/locationUtil';

// DAO & API
import assetDAO from '@/database/assets';
import assetApi from '@/api/assetApi';

// Others
import config from '@/config/env-constants';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'activate-assets',
	components: {
		Loading,
		DateRangePicker,
	},
	props: {
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		allAssetTypesObj: {
			type: Object,
			required: true,
		},
		allCompanyOptions: {
			type: Array,
			required: false,
		},
		allStorageLocationOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			companyOptions: [],
			storageLocationOptions: [],
			selCompany: config.companyDefaultValue,
			selStorageLocation: config.storageLocationDefaultValue,
			selConditions: {},
			selActivationDate: '',
			assetsToActivate: [],
			currUserId: this.$store.getters.loggedUser.id,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// table fields
			fields: [
				{
					key: 'assetCode',
					sortable: true,
				},
				'assetType',
				'condition',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			// Check for loader
			isLoading: false,
			maxDate: new Date(
				DateUtil.endDateTimeStamp(DateUtil.getCurrentTimestamp())
			),
		};
	},
	watch: {
		selCompany: function () {
			if (this.selCompany.id) {
				this.retrieveStorageLocations();
			} else {
				this.resetStorageLocationEntries();
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		activationDate: {
			get() {
				return moment(this.selActivationDate.startDate).format(
					'MMMM D YYYY, h:mm a'
				);
			},
			set(value) {
				this.selActivationDate = value.startDate;
			},
		},
		timeStampedActivationDate() {
			return moment(this.selActivationDate.startDate).valueOf();
		},
	},
	mounted() {
		EventBus.$on('onUpdateAssetsToActivate', (selAssets) => {
			this.processSelAssets(selAssets);
			this.initAssetConditions(selAssets);
			this.retrieveCompanyOptions();
			this.resetStorageLocationEntries();
		});
	},
	methods: {
		getAssetConditions(asset) {
			let conditions = [];

			let assetTypeId = asset.assetTypeId;
			let assetTypeObj = this.allAssetTypesObj[assetTypeId];

			if (assetTypeObj && assetTypeObj.conditions && !_.isEmpty(assetTypeObj.conditions)) {
				conditions = DropDownItemsUtil.retrieveAssetConditions(assetTypeObj.conditions);
			} else {
				conditions = config.assetConditionStatus;
			}

			return conditions;
		},

		async processSelAssets(selAssets) {
			// Show Loader
			this.Loading = true;

			// reset stored and inactive assets
			this.assetsToActivate = [];

			let assetCodes = _.map(selAssets, 'assetCode');
			let assetsObjResult = await assetDAO.getAssetsByCode(assetCodes);
			let assetsObj = assetsObjResult[0];

			_.forEach(assetsObj, asset => {
				if (asset.status === 'Inactive' || asset.status === 'Lost') {
					this.assetsToActivate.push(asset);
				}
			});

			this.totalRows = this.assetsToActivate.length;

			// Hide Loader
			this.Loading = false;
		},
		initAssetConditions(selAssets) {
			selAssets.forEach((asset) => {
				let assetCode = asset.assetCode;
				this.selConditions[assetCode] = config.assetCondition.GOOD;
			});
		},
		retrieveCompanyOptions() {
			if (!this.isSuperAdmin) {
				let companies = {};
				companies[this.loggedUserCompany.id] = this.loggedUserCompany;
				this.companyOptions = DropDownItemsUtil.retrieveActiveCompanies(companies);
			} else {
				this.companyOptions = this.allCompanyOptions;
			}

			// init selected company
			this.selCompany = config.companyDefaultValue;
		},
		resetStorageLocationEntries() {
			// reset entries
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		retrieveStorageLocations() {
			let vm = this;

			this.resetStorageLocationEntries();

			this.allStorageLocationOptions.forEach((locOption) => {
				let loc = locOption.value;
				if (loc.companyId === vm.selCompany.id) {
					vm.storageLocationOptions.push({
						value: { id: loc.id, name: loc.name, geoaddress: loc.geoaddress },
						text: loc.name,
					});
				}
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$.warning('Please address the field/s with invalid input.');
				return;
			}

			if (this.totalRows === 0) {
				this.$toaster.warning('No asset to activate');
				return;
			}

			this.handleSubmit();
		},
		prepareCurrentLocation() {
			return {
				company: this.selCompany.name,
				companyId: this.selCompany.id,
				storageLocation: this.selStorageLocation.name,
				storageLocationId: this.selStorageLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selStorageLocation.geoaddress
				),
				dateStored: DateUtil.getCurrentTimestamp(),
				storedBy: this.currUserId,
			};
		},
		getParam() {
			let param = {
				currUserId: this.currUserId,
				dateActivated: this.timeStampedActivationDate,
				currentLocation: this.prepareCurrentLocation(),
				assetCodes: [],
				assetConditions: this.selConditions,
				operation: config.assetHistoryOperation.ACTIVATE_ASSET,
			};

			this.assetsToActivate.forEach((asset) => {
				param.assetCodes.push(asset.assetCode);
			});

			return param;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let param = this.getParam();
				let { data } = await assetApi.activateAssets(param);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					this.$refs.modal.hide();
					EventBus.$emit('onCloseSaveAssets', data.assets);
				} else {
					this.$toaster.warning(data.message);
				}

			} catch (_error) {
				this.$toaster.error('Error activating asset(s). Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			this.selCompany = config.companyDefaultValue;
			this.selStorageLocation = config.storageLocationDefaultValue;
			this.selActivationDate = DateUtil.getCurrentTimestamp();
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateAssetsToActivate');
	},
};
</script>