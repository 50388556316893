<template>
	<b-modal id="assign-condition-on-assets" title="Assign Condition On Assets" size="lg" ref="modal" ok-title="Save"
		@ok="handleOk" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-2">
					<b-col sm="12">
						<i class="icon-direction"></i> Assign condition,
						<b-badge variant="success">Good</b-badge>,
						<b-badge variant="danger">Damaged</b-badge> or
						<b-badge variant="secondary">Others</b-badge>, on each asset.
					</b-col>
				</b-row>
				<b-row class="mb-0">
					<b-col sm="12" md="4" offset-md="8" class="mt-2 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
					<b-col sm="12" class="my-2">
						<b-table show-empty striped hover fixed :items="selAssets" :fields="fields"
							:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
							<template v-slot:cell(condition)="row">
								<b-form-select :name="row.item.assetCode + ' Condition'"
									v-model="selConditions[row.item.assetCode]" v-validate="'required'"
									:options="getAssetConditions(row.item)" class="mr-2" />
								<span v-show="errors.has(row.item.assetCode + ' Condition')" class="help-block">{{
									errors.first(row.item.assetCode + ' Condition') }}</span>
							</template>
						</b-table>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// DAO & API
import assetDAO from '@/database/assets';
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'assign-condition-on-assets',
	components: {
		Loading,
	},
	props: {
		allAssetTypesObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			selConditions: {},
			selAssets: {},
			currUserId: this.$store.getters.loggedUser.id,

			// table fields
			fields: [
				{
					key: 'assetCode',
					sortable: true,
				},
				'assetType',
				'condition',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateAssetsToAssignCondition', (selAssets) => {
			this.selAssets = selAssets;
			this.processSelAssets(selAssets);
		});
	},
	methods: {
		getAssetConditions(asset) {
			let conditions = [];

			let assetTypeId = asset.assetTypeId;
			let assetTypeObj = this.allAssetTypesObj[assetTypeId];

			if (assetTypeObj && assetTypeObj.conditions && !_.isEmpty(assetTypeObj.conditions)) {
				conditions = DropDownItemsUtil.retrieveAssetConditions(assetTypeObj.conditions);
			} else {
				conditions = config.assetConditionStatus;
			}

			return conditions;
		},

		async processSelAssets(selAssets) {
			// Show Loader
			this.Loading = true;

			let assetCodes = _.map(selAssets, 'assetCode');
			let assetsObjResult = await assetDAO.getAssetsByCode(assetCodes);
			let assetsObj = assetsObjResult[0];

			_.forEach(assetsObj, asset => {
				let assetCode = asset.assetCode;
				let assetDetails = asset.details;
				let condition =
					assetDetails.condition && assetDetails.condition.length > 0
						? assetDetails.condition
						: null;
				this.selConditions[assetCode] = condition;
			});

			this.totalRows = _.size(assetsObj);

			// Hide Loader
			this.Loading = false;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			}

			this.handleSubmit();
		},
		getParam() {
			let assets = {};

			let assetCodes = _.map(this.selAssets, 'assetCode');
			for (const assetCode of assetCodes) {
				let index = _.findIndex(this.selAssets, function (o) {
					return o.assetCode === assetCode;
				});
				assets[assetCode] = this.selAssets[index];
				if (_.isEmpty(assets[assetCode].details)) {
					assets[assetCode].details = {};
				}
				assets[assetCode].details.condition = this.selConditions[assetCode];
			}

			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assets: assets,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let param = this.getParam();
				let { data } = await assetApi.updateAssets(param);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveAssets');
					this.$refs.modal.hide();
				} else {
					this.$toaster.warning(data.message);
				}

			} catch (_error) {
				this.$toaster.error('Error assigning condition on asset(s). Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateAssetsToAssignCondition');
	},
};
</script>