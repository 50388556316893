<template>
	<b-modal id="change-assets-location" title="Change Assets Location" size="lg" ref="modal" ok-title="Save" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-2">
					<b-col sm="12" class="mb-2">
						<b>
							<i class="icon-location-pin"></i> &nbsp;Last Known Locations
						</b>
					</b-col>
					<b-col sm="12" class="mb-4">
						NOTE: You can only change the location of
						<b-badge variant="primary">Stored</b-badge> asset. The following are
						the list of all selected assets with existing locations.
					</b-col>
					<b-col sm="12" md="4" offset-md="8" class="mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
					<b-col>
						<b-table show-empty striped hover :items="storedAssets" :fields="fields" :current-page="currentPage"
							:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
							:sort-direction="sortDirection" responsive />
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>

				<b-row class="mb-2">
					<b-col sm="6">
						<b> <i class="icon-location-pin"></i> &nbsp;New Location </b>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Company:">
							<v-select name="Company" class="style-chooser" label="text" :options="companyOptions"
								:reduce="(company) => company.value" v-model="selCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">{{
								errors.first('Company')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Storage Location">
							<v-select name="Storage Location" class="style-chooser" label="text"
								:options="storageLocationOptions" :reduce="(loc) => loc.value" v-model="selStorageLocation"
								v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a storage location
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Storage Location')" class="help-block">
								{{ errors.first('Storage Location') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col sm="12">
						<b>Other Details </b>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="6">
						<b-form-group label="Purpose">
							<b-form-select name="Purpose" v-model="selPurpose" v-validate="'required'"
								:options="purposeOptions" class="mr-2" />
							<span v-show="errors.has('Purpose')" class="help-block">{{
								errors.first('Purpose')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group v-show="showOthersField" label="If Others, please specify" label-for="others">
							<b-form-input name="Others" v-model="others" type="text" v-validate="{
								required: showOthersField,
								regex: remarksRegex,
							}" placeholder="Please specify" />
							<span v-show="errors.has('Others')" class="help-block">
								{{ errors.first('Others') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { LocationUtil } from '@/utils/locationUtil';

// DAO & API
import assetDAO from '@/database/assets';
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'change-assets-location',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allStorageLocationOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selCompany: config.companyDefaultValue,
			selStorageLocation: config.storageLocationDefaultValue,
			selOwnership: null,
			selPurpose: null,
			others: '',

			storageLocationOptions: [],
			currUserId: this.$store.getters.loggedUser.id,
			purposeOptions: config.changeLocationPurpose,
			showOthersField: false,

			storedAssets: [],

			// table fields
			fields: [
				{
					key: 'assetCode',
					sortable: true,
				},
				{
					key: 'currentLocation.company',
					label: 'Current Company',
					sortable: false,
				},
				{
					key: 'currentLocation.storageLocation',
					label: 'Current Storage Location',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selCompany: function () {
			if (this.selCompany.id) {
				this.retrieveStorageLocations();
			} else {
				this.resetStorageLocationEntries();
			}
		},
		selPurpose: function () {
			if (this.selPurpose === 'Others') {
				this.showOthersField = true;
			} else {
				this.showOthersField = false;
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
	},
	mounted() {
		this.resetStorageLocationEntries();

		EventBus.$on('onUpdateAssetsToChangeLocation', (selAssets) => {
			this.processSelAssets(selAssets);
		});
	},
	methods: {
		async processSelAssets(selAssets) {
			// Show Loader
			this.Loading = true;

			// reset stored assets
			this.storedAssets = [];

			let assetCodes = _.map(selAssets, 'assetCode');
			let assetsObjResult = await assetDAO.getAssetsByCode(assetCodes);
			let assetsObj = assetsObjResult[0];

			_.forEach(assetsObj, asset => {
				if (asset.status === 'Stored') {
					this.storedAssets.push(asset);
				}
			});

			this.totalRows = this.storedAssets.length;

			// Hide Loader
			this.Loading = false;
		},
		resetStorageLocationEntries() {
			// reset entries
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		retrieveStorageLocations() {
			let vm = this;

			this.resetStorageLocationEntries();

			this.allStorageLocationOptions.forEach((locOption) => {
				let loc = locOption.value;
				if (loc.companyId === vm.selCompany.id) {
					vm.storageLocationOptions.push({
						value: { id: loc.id, name: loc.name, geoaddress: loc.geoaddress },
						text: loc.name,
					});
				}
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		hasAssetWithSameLocationOnNewLocation() {
			for (const selAsset of this.storedAssets) {
				let currentLocation = selAsset.currentLocation;
				if (
					this.selCompany.id === currentLocation.companyId &&
					this.selStorageLocation.id === currentLocation.storageLocationId
				) {
					return true;
				}
			}

			return false;
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			}

			// if purpose is Others, Others field is required
			if (this.selPurpose === 'Others' && this.others.length === 0) {
				this.$toaster.warning('Please specify the purpose of location change.');
				return;
			}

			// the new location must not be the same with the previous location for any selected assets
			if (this.hasAssetWithSameLocationOnNewLocation()) {
				this.$toaster.warning(
					'All selected asset(s) must be transferred to a different location.'
				);
				return;
			}

			this.handleSubmit();
		},
		prepareCurrentLocation() {
			let notes = this.selPurpose;
			if (this.selPurpose === 'Others') {
				// Removes excess whitespace
				notes = ValidationUtil.removeExcessWhiteSpace(this.others);
			}

			return {
				company: this.selCompany.name,
				companyId: this.selCompany.id,
				storageLocation: this.selStorageLocation.name,
				storageLocationId: this.selStorageLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selStorageLocation.geoaddress
				),
				dateStored: DateUtil.getCurrentTimestamp(),
				storedBy: this.currUserId,
				notes: notes,
			};
		},
		getParam() {
			let param = {
				currCompanyId: this.prepareCurrentLocation().companyId,
				currUserId: this.currUserId,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				newLocation: this.prepareCurrentLocation(),
				assetCodes: [],
				operation: config.assetHistoryOperation.CHANGE_ASSET_LOCATION,
			};

			this.storedAssets.forEach((asset) => {
				param.assetCodes.push(asset.assetCode);
			});

			return param;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let param = this.getParam();
				let { data } = await assetApi.changeAssetsLocation(param);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					this.$refs.modal.hide();
					EventBus.$emit('onCloseSaveAssets', data.assets);
				} else {
					this.$toaster.warning(data.message);
				}

			} catch (_error) {
				this.$toaster.error('Error changing location of asset(s). Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			this.selCompany = config.companyDefaultValue;
			this.selStorageLocation = config.storageLocationDefaultValue;
			this.selOwnership = null;
			this.selPurpose = null;
			this.others = '';
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateAssetsToChangeLocation');
	},
};
</script>