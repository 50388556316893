<template>
	<b-modal id="mark-as-lost-assets" title="Mark as Lost Assets" size="lg" ref="modal" ok-title="Save" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-2">
					<b-col sm="12" class="mb-2">
						<b> <i class="icon-eyeglass"></i> &nbsp;Last Known Locations </b>
					</b-col>
					<b-col sm="12" class="mb-4">
						NOTE: You can only mark as lost those assets with active status. The
						following are the list of all selected assets with
						<b-badge variant="primary">Stored</b-badge> status.
					</b-col>
					<b-col sm="12" md="4" offset-md="8" class="mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
					<b-col>
						<b-table show-empty striped hover :items="activeAssets" :fields="fields" :current-page="currentPage"
							:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
							:sort-direction="sortDirection" responsive />
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col sm="12">Are you sure to mark these assets as lost?</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// DAO & API
import assetDAO from '@/database/assets';
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'mark-as-lost-assets',
	components: {
		Loading,
	},
	data() {
		return {
			activeAssets: [],
			fields: [
				{
					key: 'assetCode',
					sortable: true,
				},
				{
					key: 'status',
					sortable: true,
				},
				{
					key: 'currentLocation.company',
					label: 'Current Company',
					sortable: false,
				},
				{
					key: 'currentLocation.storageLocation',
					label: 'Current Storage Location',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			currUserId: this.$store.getters.loggedUser.id,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateAssetsToMarkAsLost', (selAssets) => {
			this.processSelAssets(selAssets);
		});
	},
	methods: {
		async processSelAssets(selAssets) {
			// Show Loader
			this.Loading = true;

			// reset stored and inactive assets
			this.activeAssets = [];

			let assetCodes = _.map(selAssets, 'assetCode');
			let assetsObjResult = await assetDAO.getAssetsByCode(assetCodes);
			let assetsObj = assetsObjResult[0];

			_.forEach(assetsObj, asset => {
				if (asset.status === 'Stored' || asset.status === 'In-Transit') {
					this.activeAssets.push(asset);
				}
			});

			this.totalRows = this.activeAssets.length;

			// Hide Loader
			this.Loading = false;
		},
		handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let inTransitAssets = _.filter(this.activeAssets, (o) => {
				return o.status === 'In-Transit';
			});
			let inTransitAssetCodes = _.map(inTransitAssets, 'assetCode');

			if (inTransitAssetCodes.length > 0) {
				this.$toaster.error(
					'Unable to mark as lost In-Transit asset(s) ' +
					JSON.stringify(inTransitAssets)
				);
				return;
			}

			this.handleSubmit();
		},
		getParam() {
			let param = {
				currUserId: this.currUserId,
				dateDeactivated: DateUtil.getCurrentTimestamp(),
				assetCodes: [],
			};

			this.activeAssets.forEach((asset) => {
				if (asset.status !== 'In-Transit') {
					param.assetCodes.push(asset.assetCode);
				}
			});

			return param;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let param = this.getParam();
				let { data } = await assetApi.markAsLostAssets(param);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveAssets', data.assets);
					this.$refs.modal.hide();
				} else {
					this.$toaster.warning(data.message);
				}

			} catch (_error) {
				this.$toaster.error('Error marking asset(s) as lost. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateAssetsToMarkAsLost');
	},
};
</script>